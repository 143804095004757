import { useEffect } from 'react';

import { fg } from '@confluence/feature-gating';

import {
	legacyUpdateVisibleReplies,
	updateVisibleReplies,
	type LegacyUpdateVisibleRepliesProps,
} from '../helper/commentThreadHelper';

export const useUpdateVisibleReplies = ({
	allReplies,
	commentType,
	isParentCommentOpen,
	currentReplyView,
	latestReplies,
	latestReplyIds,
	parentCommentId,
	setVisibleReplies,
	shouldShowLatestReplies,
	numOfHiddenReplies,
}: LegacyUpdateVisibleRepliesProps) => {
	useEffect(() => {
		if (!fg('confluence_frontend_cp_visible_replies_refactor')) return;

		updateVisibleReplies({
			parentCommentId,
			currentReplyView,
			setVisibleReplies,
			allReplies,
			numOfHiddenReplies,
			isParentCommentOpen,
			latestReplies,
		});
	}, [
		allReplies,
		currentReplyView,
		isParentCommentOpen,
		latestReplies,
		numOfHiddenReplies,
		parentCommentId,
		setVisibleReplies,
	]);

	useEffect(() => {
		if (fg('confluence_frontend_cp_visible_replies_refactor')) return;

		legacyUpdateVisibleReplies({
			allReplies,
			commentType,
			isParentCommentOpen,
			currentReplyView,
			latestReplies,
			latestReplyIds,
			parentCommentId,
			setVisibleReplies,
			shouldShowLatestReplies,
			numOfHiddenReplies,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps -- there might be a better way to handle this without needing a useEffect
	}, [allReplies, latestReplies, latestReplyIds]);
};
