/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import type { FC } from 'react';
import React, { useCallback, useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import type { DocNode } from '@atlaskit/adf-schema';
import { CommentEditor } from '@atlaskit/editor-core/appearance-editor-comment';
import { ChromelessEditor } from '@atlaskit/editor-core/appearance-editor-chromeless';

import {
	ADD_PAGE_COMMENT_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { CommentEditorWithoutSpinner } from '@confluence/comment';
import type { EditorCommentMode } from '@confluence/comment';
import { usePageContentId, useContentType } from '@confluence/page-context';
import { useSpaceId } from '@confluence/space-utils';

import type {
	PageCommentEditorContentOperationsQuery as PageCommentEditorContentOperationsQuery$data,
	PageCommentEditorContentOperationsQueryVariables as PageCommentEditorContentOperationsQuery$variables,
} from './__types__/PageCommentEditorContentOperationsQuery';
import type { CommentSaveHandler } from './commentSimpleTypes';

type PageCommentEditorProps = {
	mode: EditorCommentMode;
	saveHandler: CommentSaveHandler;
	cancelHandler: ({ isEditorAborted }: { isEditorAborted: any }) => void;
	onEditorLoaded: () => void;
	onEditorContentChanged?: (hasChanges: boolean) => void;
	afterActivate?: () => void;
	content?: DocNode;
	useNewExperienceAbort?: boolean;
	expandEditor?: boolean;
	isCommentsPanel?: boolean;
};

export const PageCommentEditor: FC<PageCommentEditorProps> = ({
	mode,
	saveHandler,
	cancelHandler,
	onEditorLoaded,
	onEditorContentChanged,
	afterActivate,
	content,
	useNewExperienceAbort,
	expandEditor,
	isCommentsPanel,
}) => {
	const [contentId] = usePageContentId();
	const [contentType] = useContentType();
	const spaceId = useSpaceId();
	const { data } = useQuery<
		PageCommentEditorContentOperationsQuery$data,
		PageCommentEditorContentOperationsQuery$variables
	>(
		gql`
			query PageCommentEditorContentOperationsQuery($contentId: ID!, $status: [String]) {
				content(id: $contentId, status: $status) {
					nodes {
						id
						type
						operations {
							operation
							targetType
						}
					}
				}
			}
		`,
		{
			variables: { contentId: contentId ?? '' },
			skip: !contentId,
		},
	);

	const experienceTracker: any = useContext(ExperienceTrackerContext);
	const operations = data?.content?.nodes?.[0]?.operations ?? [];
	const canEditPage =
		operations.filter((op) => op?.operation === 'update' && op?.targetType === contentType).length >
		0;
	// Simulate the unmount function for the other editor
	useEffect(
		() => () => {
			cancelHandler({ isEditorAborted: false });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	useEffect(() => {
		const handleExperienceAbort = () => {
			experienceTracker.abort(ADD_PAGE_COMMENT_EXPERIENCE);
		};

		if (useNewExperienceAbort) {
			window.addEventListener('beforeunload', handleExperienceAbort);
		}

		return () => {
			if (useNewExperienceAbort) {
				window.removeEventListener('beforeunload', handleExperienceAbort);
			}
		};
	}, [experienceTracker, useNewExperienceAbort]);

	const handleSave = useCallback(
		(adf: any, onSuccess: () => void) =>
			saveHandler({ adf }, onSuccess).catch((err) => {
				// There is another handler higher up to re-enable the buttons, throw the error to allow for that
				throw err;
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[saveHandler],
	);

	const handleCancel = useCallback(() => {
		cancelHandler({ isEditorAborted: true });
	}, [cancelHandler]);

	return (
		<CommentEditorWithoutSpinner
			pageId={contentId!}
			pageType={contentType!}
			appearance={isCommentsPanel ? 'chromeless' : 'comment'}
			EditorComponent={isCommentsPanel ? ChromelessEditor : CommentEditor}
			commentMode={mode}
			commentType="page"
			spaceId={spaceId!}
			onSaveComment={handleSave}
			content={content}
			onCancelComment={handleCancel}
			onEditorReady={onEditorLoaded}
			onContentChange={onEditorContentChanged}
			afterActivation={afterActivate}
			hasMediaUploadPermissions={canEditPage}
			shouldWarnOnInternalNavigation
			expandEditor={expandEditor}
			showCancelButton={isCommentsPanel}
			useNewWarningModal={isCommentsPanel}
			isCommentsPanel={isCommentsPanel}
		/>
	);
};
