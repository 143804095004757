import React, { useContext, useState, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { defineMessages, useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import { AnnotationMarkStates, AnnotationTypes } from '@atlaskit/adf-schema';
import type { AddMarkStep } from '@atlaskit/editor-prosemirror/transform';

import { fg } from '@confluence/feature-gating';
import {
	getEditorAnnotationEventEmitter,
	getRendererAnnotationEventEmitter,
} from '@confluence/annotation-event-emitter';
import {
	useCommentsData,
	CommentActionType,
	CommentType,
	type CommentData,
	type ReplyData,
} from '@confluence/comments-data';
import { constructStepForGql } from '@confluence/comments-util';
import {
	ReactionsContext,
	useCommentsContentActions,
	useUpdateDocument,
} from '@confluence/comment-context';
import { CommentBody, EditComment } from '@confluence/inline-comments-common';
import {
	handleResolveSuccess,
	handleDeleteSuccess,
	handleMutationFailure,
	insertReopenedCommentAnnotation,
	updateApolloCacheCallback as updateApolloInlineReplyCacheCallback,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsUtils';
import type { CommentAction } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { usePageContentId } from '@confluence/page-context';
import {
	CommentDeletionLocation,
	CommentsPanelQuery,
	InlineCommentsQuery,
	DeleteInlineCommentMutation,
	DeleteInlineCommentMutationWithStep,
	ResolveInlineCommentMutation,
	ReopenCommentMutation,
} from '@confluence/inline-comments-queries';
import {
	ActiveCommentsQuery,
	type ActiveCommentsQueryType,
	type CommentFooterLocation,
	ResolveCommentMutation,
} from '@confluence/comments-panel-queries';
import { useInlineCommentQueryParams } from '@confluence/comment';
import type {
	CommentsPanelQueryType,
	InlineCommentAuthorUser,
	TopLevelComment,
	ReopenCommentMutationType,
	ReopenCommentMutationVariables,
	CommentLocation,
	GraphQLContentStatus,
	CommentInlineResolveProperties,
} from '@confluence/inline-comments-queries';
import { useInlineComments, useEditorAnnotations } from '@confluence/inline-comments-hooks';
import {
	RESOLVE_INLINE_COMMENT_EXPERIENCE,
	ExperienceTrackerContext,
	DELETE_INLINE_COMMENT_EXPERIENCE,
	DELETE_PAGE_COMMENT_EXPERIENCE,
} from '@confluence/experience-tracker';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import type { PageInfoNode } from '@confluence/page-info';
import type { FlagsStateContainer } from '@confluence/flags';
import { useRendererActions } from '@confluence/renderer-actions';
import { useAnnotationsDispatch } from '@confluence/annotation-provider-store';
import { i18n as commentsI18n } from '@confluence/inline-comments-common/entry-points/i18n';
import { DEFAULT_LIMIT } from '@confluence/page-comments-queries';
import {
	CommentsSectionQuery,
	CommentsSectionWithoutReactionsQuery,
} from '@confluence/page-comments-queries/entry-points/CommentsSectionQuery.graphql';
import type {
	CommentsSectionQuery as CommentsSectionQueryType,
	CommentsSectionWithoutReactionsQuery as CommentsSectionWithoutReactionsQueryType,
	CommentsSectionQueryVariables,
} from '@confluence/page-comments-queries/entry-points/__types__/CommentsSectionQuery';
import { DeleteGeneralCommentMutation } from '@confluence/page-comments-queries/entry-points/DeleteGeneralCommentMutation.graphql';
import type {
	DeleteGeneralCommentMutationData,
	DeleteGeneralCommentMutationVariables,
} from '@confluence/page-comments-queries/entry-points/__types__/DeleteGeneralCommentMutation';
import {
	updateApolloCacheParentCallback as updateApolloCacheGeneralParentCallback,
	updateApolloCacheReplyCallback as updateApolloCacheGeneralReplyCallback,
} from '@confluence/page-comments-queries/entry-points/pageCommentUtils';
import { useSessionData } from '@confluence/session-data';
import { getLogger } from '@confluence/logger';
import {
	ViewValues,
	useCommentsPanel,
	useCommentsPanelScroll,
} from '@confluence/comments-panel-utils';

import { useShowCommentsPanel } from '../useShowCommentsPanel';
import { AnalyticsSource } from '@confluence/comments-util/entry-points/analytics';

type CommentProps = {
	comment: CommentData | ReplyData;
	supportedTopLevelActions: CommentAction[];
	pageMode: PageMode;
	pageInfo: PageInfoNode | null;
	isUnread?: boolean;
	isHovered?: boolean;
	threadKey: string;
	flags?: FlagsStateContainer;
	isAnnotatedTextPressed?: boolean;
	setAnnotatedTextPressed?: (state: boolean) => void;
	adjustForNestedReplies?: boolean;
	hideBranchingStyle?: boolean;
	canAddComments?: boolean;
	isThreadHovered?: boolean;
	isParentCommentOpen?: boolean;
};

const i18n = defineMessages({
	commentReopenedFlagTitle: {
		id: 'comments-panel.comment.reopened.flag.title',
		defaultMessage: 'Comment reopened',
		description:
			'Title to display in Flag to inform user when the comment was successfully reopened.',
	},
	commentReopenFailedFlagTitle: {
		id: 'comments-panel.comment.reopen.failed.flag.title',
		defaultMessage: 'Failed to reopen comment',
		description: 'Title to display in Flag to inform user when the comment failed to reopen.',
	},
	retryCTA: {
		id: 'comments-panel.comment.reopen.failed.flag.cta.retry',
		defaultMessage: 'Retry now',
		description:
			'CTA button text displayed in Error Flag for users to retry the failed comment reopen action.',
	},
});

const logger = getLogger('page-comments-queries');

// this component can be used for parent comment and a reply
export const Comment = ({
	comment,
	supportedTopLevelActions,
	pageMode,
	pageInfo,
	isUnread = false,
	isHovered,
	threadKey,
	flags,
	isAnnotatedTextPressed,
	setAnnotatedTextPressed,
	adjustForNestedReplies = false,
	hideBranchingStyle = false,
	canAddComments = true,
	isThreadHovered,
	isParentCommentOpen,
}: CommentProps) => {
	const [editCommentId, setCommentForEdit] = useState('');
	const { isReactionsEnabled } = useContext(ReactionsContext);

	const { setEditorViewProps } = useAnnotationsDispatch();

	const experienceTracker = useContext(ExperienceTrackerContext);
	const { resetContentChanged } = useCommentsContentActions();
	const [contentId] = usePageContentId();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { removeUnresolvedInlineComment, addUnresolvedInlineComment }] = useInlineComments();
	const [
		{ commentsDataMap, orderedActiveAnnotationIdList },
		{
			addNewCommentThreads,
			deleteParentComment,
			handleRemovingComments,
			updateCommentCount,
			setOrderedActiveAnnotationIdList,
		},
	] = useCommentsData();
	const [{ annotations }] = useEditorAnnotations();
	const { rendererActions } = useRendererActions();
	const [{ updateDocumentFn }] = useUpdateDocument();
	const { cloudId } = useSessionData();

	const [
		{ currentlySelectedCommentMarkerRef },
		{ setCurrentView, setCurrentlySelectedCommentMarkerRef, setReplyToCommentId },
	] = useCommentsPanel();
	const { formatMessage } = useIntl();
	const { focusedCommentId } = useInlineCommentQueryParams();
	const { scrollCommentsPanelToThread } = useCommentsPanelScroll();
	const { isCommentsPanelShown, showCommentsPanel } = useShowCommentsPanel();

	const [resolveInlineCommentFn] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ResolveInlineCommentMutation,
	);

	const [resolveCommentFn] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ResolveCommentMutation,
	);
	const resolveCommentMutationFn = fg('confluence_frontend_comments_panel_v2')
		? resolveCommentFn
		: resolveInlineCommentFn;

	const [reopenCommentFn] = useMutation<
		ReopenCommentMutationType,
		ReopenCommentMutationVariables
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations
	>(ReopenCommentMutation, {
		refetchQueries:
			// Copied from the existing dialog flow to support standalone experience
			pageMode === PageMode.VIEW
				? [{ query: InlineCommentsQuery, variables: { pageId: contentId } }]
				: [],
	});

	const [deleteTopLevelRendererInlineCommentFn] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DeleteInlineCommentMutationWithStep,
	);
	const [deleteInlineCommentFn] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DeleteInlineCommentMutation,
	);

	const [deleteGeneralCommentFn] = useMutation<
		DeleteGeneralCommentMutationData,
		DeleteGeneralCommentMutationVariables
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
	>(DeleteGeneralCommentMutation);

	const eventEmitter =
		pageMode === PageMode.VIEW
			? getRendererAnnotationEventEmitter()
			: getEditorAnnotationEventEmitter();

	// @ts-ignore FIXME: `contentId` can be `undefined` here, and needs proper handling
	const pageId: string = contentId;

	const userId = (comment?.author as InlineCommentAuthorUser)?.accountId;
	const topCommentUserAvatar = comment?.author?.profilePicture?.path;
	const topCommentDisplayName = comment?.author?.displayName ?? 'Anonymous';
	const commentId = comment?.id;
	const date = comment?.version?.when;
	const createdAtNonLocalized = comment?.createdAtNonLocalized;
	const versionDate = comment?.version?.when;
	const permissions = comment?.permissions;
	const content = comment?.body?.value;
	const permissionType = comment?.author?.permissionType ?? undefined;
	const parentCommentId = comment?.parentId;
	const isReply = !!parentCommentId;
	const isEdited = (comment?.version?.number || 0) > 1;
	const numReplies = (comment as TopLevelComment)?.replies?.length || 0;
	const commentUrl = comment?.links.webui ?? undefined;
	const inheritedReactionsData = comment?.reactionsSummary;

	const inlineCommentlocation = comment?.location as CommentLocation;
	const footerCommentLocation = comment?.location as CommentFooterLocation;

	const commentType =
		inlineCommentlocation?.type === 'INLINE' ? CommentType.INLINE : CommentType.GENERAL;

	const getResolvedProperties = useCallback(
		(type: CommentType) => {
			const properties =
				type === CommentType.INLINE
					? inlineCommentlocation?.inlineResolveProperties
					: footerCommentLocation?.commentResolveProperties;
			// resolve properties only show for resolved parent comments
			// once you reopen a comment, the resolveProperties will still be there so that it can continue to be
			// reused since we don't refetch data for that comment. this is why it's important to check if the comment is open or not
			if (!isReply && !(comment as CommentData).isOpen) {
				return properties?.resolved ? properties : undefined;
			}
			return undefined;
		},
		[inlineCommentlocation, footerCommentLocation, isReply, comment],
	);

	const resolveProperties = getResolvedProperties(commentType);

	const annotatedText = inlineCommentlocation?.inlineText || undefined;

	const isCommentRemovedByAnotherUser = comment?.wasRemovedByAnotherUser;

	const isResolvedByAnotherUser =
		isCommentRemovedByAnotherUser &&
		isCommentRemovedByAnotherUser === CommentActionType.RESOLVE_COMMENT_THREAD;
	const isDeletedByAnotherUser =
		isCommentRemovedByAnotherUser &&
		isCommentRemovedByAnotherUser === CommentActionType.DELETE_COMMENT;

	const handleDeleteInlineComment = () => {
		// NOTE: The DELETE experience is started in CommentActions.tsx
		const isTopLevelRendererComment = pageMode === PageMode.VIEW && !isReply;
		let step: AddMarkStep | undefined;

		if (isTopLevelRendererComment) {
			const deleteResult = rendererActions?.deleteAnnotation(threadKey, 'inlineComment');
			if (deleteResult) {
				step = deleteResult.step as AddMarkStep;
			} else {
				void handleMutationFailure({
					experienceTracker,
					experienceName: DELETE_INLINE_COMMENT_EXPERIENCE,
					error: new Error('Unable to generate step for delete mutation'),
				});
				return;
			}
		}

		const deleteMutation = isTopLevelRendererComment
			? deleteTopLevelRendererInlineCommentFn
			: deleteInlineCommentFn;

		const deleteVariables = {
			variables: isTopLevelRendererComment
				? {
						input: {
							commentId,
							step: step ? constructStepForGql(step) : {},
						},
					}
				: {
						commentIdToDelete: commentId,
						// There is no VIEW option
						deleteFrom:
							pageMode === PageMode.EDIT
								? CommentDeletionLocation.EDITOR
								: CommentDeletionLocation.LIVE,
					},
			update: isReply
				? updateApolloInlineReplyCacheCallback(
						'delete',
						{
							pageId,
							contentStatus: ['CURRENT', 'DRAFT'] as GraphQLContentStatus[],
						},
						parentCommentId,
						commentId,
					)
				: undefined,
		};

		deleteMutation({
			...deleteVariables,
		})
			.then(({ data }) => {
				updateCommentCount({
					threadKey,
					actionType: CommentActionType.DELETE_COMMENT,
					commentType,
				});

				if (
					!data ||
					(isTopLevelRendererComment && !data.deleteInlineComment) ||
					(!isTopLevelRendererComment && !data.deleteComment)
				) {
					throw new Error('No data returned from mutation');
				}

				const cleanUpReply = () => {
					handleRemovingComments({
						threadKey,
						commentId,
						action: CommentActionType.DELETE_COMMENT,
						commentType,
					});

					experienceTracker.succeed({
						name: DELETE_INLINE_COMMENT_EXPERIENCE,
					});
				};

				const deleteAnnotationAndCleanUpParent = (annotationId: string) => {
					// For a top level comment in renderer, we need to send out the events to tell the
					// renderer to deselect the annotation and remove it from the document
					if (pageMode === PageMode.VIEW) {
						const deleteResult = rendererActions?.deleteAnnotation(annotationId, 'inlineComment');

						if (deleteResult && deleteResult.doc) {
							// Inform the Renderer to de-select the annotation
							eventEmitter.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_FOCUS);
							eventEmitter.emit(AnnotationUpdateEvent.DESELECT_ANNOTATIONS);
							updateDocumentFn && updateDocumentFn(deleteResult.doc);
						}
						// For editor/live pages we need to send the event to delete the mark
					} else {
						eventEmitter.emit('delete', annotationId);
					}

					resetContentChanged();
					deleteParentComment({
						threadKey,
						commentType,
					});

					if (commentType === CommentType.INLINE) {
						setOrderedActiveAnnotationIdList(
							orderedActiveAnnotationIdList
								.map((status) => status.threadKey)
								.filter((id) => id !== threadKey),
						);
					}

					experienceTracker.succeed({
						name: DELETE_INLINE_COMMENT_EXPERIENCE,
					});
				};

				handleDeleteSuccess({
					commentId,
					annotationId: threadKey,
					pageId,
					pageMode,
					isReply,
					source: AnalyticsSource.COMMENTS_PANEL,
					inlineNodeTypes: undefined, // We need to figure out how to get this data
					createAnalyticsEvent,
					removeUnresolvedInlineComment,
					onSuccess: isReply ? cleanUpReply : deleteAnnotationAndCleanUpParent,
				});
			})
			.catch((err) => {
				void handleMutationFailure({
					experienceTracker,
					experienceName: DELETE_INLINE_COMMENT_EXPERIENCE,
					error: err,
				});
			});
	};

	const handleDeleteGeneralComment = () => {
		let deleteLocation: CommentDeletionLocation | undefined;

		// There is no deleteLocation for View Page
		if (pageMode !== PageMode.VIEW) {
			deleteLocation =
				pageMode === PageMode.EDIT ? CommentDeletionLocation.EDITOR : CommentDeletionLocation.LIVE;
		}

		const deleteVariables = {
			variables: {
				commentIdToDelete: commentId,
				deleteFrom: deleteLocation,
			},
			update: isReply
				? updateApolloCacheGeneralReplyCallback(
						'delete',
						{
							pageId,
							contentStatus: ['DRAFT', 'CURRENT'] as GraphQLContentStatus[],
						},
						parentCommentId,
						isReactionsEnabled,
						commentId,
					)
				: updateApolloCacheGeneralParentCallback(
						'delete',
						{
							pageId,
							contentStatus: ['DRAFT', 'CURRENT'] as GraphQLContentStatus[],
						},
						isReactionsEnabled,
						commentId,
					),
		};

		deleteGeneralCommentFn({ ...deleteVariables })
			.then(({ data }) => {
				if (!data || !data.deleteComment) {
					throw new Error('No data returned from mutation');
				}

				resetContentChanged();

				if (isReply) {
					handleRemovingComments({
						threadKey,
						commentId,
						action: CommentActionType.DELETE_COMMENT,
						commentType,
					});
				} else {
					deleteParentComment({
						threadKey,
						commentType,
					});
				}

				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'deleted',
						actionSubject: 'comment',
						actionSubjectId: commentId,
						objectType: 'page',
						objectId: pageId,
						source: AnalyticsSource.COMMENTS_PANEL,
						attributes: {
							commentType: 'page',
							mode: pageMode,
							isReply,
						},
					},
				}).fire();

				experienceTracker.succeed({
					name: DELETE_PAGE_COMMENT_EXPERIENCE,
				});
			})
			.catch((err) => {
				void handleMutationFailure({
					experienceTracker,
					experienceName: DELETE_PAGE_COMMENT_EXPERIENCE,
					error: err,
				});
			});
	};

	const handleEditComment = () => {
		setCommentForEdit(commentId);
	};

	const exitCommentEditor = () => {
		setCommentForEdit('');
	};

	const handleReplyToComment = (id: string) => {
		setReplyToCommentId(id);
	};

	const handleReopenComment = () => {
		// NOTE: The reopen experience is started in CommentActions.tsx
		reopenCommentFn({
			variables: { commentId, cloudId },
		})
			.then(() => {
				// Update the list of active annotationIds
				addUnresolvedInlineComment(threadKey, pageMode);

				updateCommentCount({
					threadKey,
					actionType: CommentActionType.REOPEN_COMMENT_THREAD,
					commentType,
				});

				// Emit the event to editor/renderer to reactivate the annotationId
				if (commentType === CommentType.INLINE) {
					if (pageMode === PageMode.VIEW) {
						const emitter = getRendererAnnotationEventEmitter();
						emitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_STATE, {
							[threadKey]: {
								id: threadKey,
								annotationType: AnnotationTypes.INLINE_COMMENT,
								state: AnnotationMarkStates.ACTIVE,
							},
						});
					} else {
						const emitter = getEditorAnnotationEventEmitter();
						emitter.emit('unresolve', threadKey);
					}
				}

				// Update the state for panel experience
				const commentsDataMapClone = { ...commentsDataMap };
				const commentInMap = commentsDataMapClone[commentType][threadKey];
				if (commentInMap) {
					commentInMap.isOpen = true;
					commentInMap.wasRemovedByAnotherUser = false;

					addNewCommentThreads(commentsDataMapClone);
				}

				// Show the reopen flag
				void flags?.showSuccessCircleFlag({
					id: commentId,
					title: formatMessage(i18n.commentReopenedFlagTitle),
					isAutoDismiss: true,
					actions: [
						{
							content: formatMessage(commentsI18n.goToCommentCTA),
							onClick: () => {
								if (!isCommentsPanelShown) {
									showCommentsPanel({ openWithView: ViewValues.OPEN });
								} else {
									setCurrentView(ViewValues.OPEN);
								}
								if (commentType === CommentType.INLINE) {
									setCurrentlySelectedCommentMarkerRef(threadKey);
									scrollCommentsPanelToThread(threadKey);
								}
								void flags.hideFlag(flags.state.flags[0]?.id);
							},
						},
						{
							content: formatMessage(commentsI18n.undoCTA),
							onClick: () => {
								handleResolveComment();
								void flags.hideFlag(flags.state.flags[0]?.id);
							},
						},
					],
				});

				if (commentType === CommentType.INLINE) {
					const orderedActiveAnnotationIds = orderedActiveAnnotationIdList.map(
						(annotation) => annotation.threadKey,
					);

					// only insert reopened comment annotation if the comment highlight exists
					if (!(resolveProperties as CommentInlineResolveProperties)?.resolvedByDangling) {
						let annotationsList = annotations;
						if (pageMode === PageMode.VIEW) {
							const annotationMarks = rendererActions?.getAnnotationMarks();
							annotationsList = annotationMarks?.map((mark) => mark.attrs.id) ?? [];
						}

						if (annotationsList.length > 0) {
							insertReopenedCommentAnnotation(
								annotationsList,
								threadKey,
								orderedActiveAnnotationIds,
								setOrderedActiveAnnotationIdList,
							);
						}
					}
				}
			})
			.catch(() => {
				void flags?.showErrorFlag({
					id: commentId,
					title: formatMessage(i18n.commentReopenFailedFlagTitle),
					isAutoDismiss: true,
					actions: [
						{
							content: formatMessage(i18n.retryCTA),
							onClick: () => {
								void flags.hideFlag(flags.state.flags[0]?.id);
								handleReopenComment();
							},
						},
					],
				});
			});
	};

	const handleResolveComment = () => {
		const mutationVariables = fg('confluence_frontend_comments_panel_v2')
			? { commentIds: [commentId], cloudId }
			: { commentId, resolved: true };
		const query = fg('confluence_frontend_comments_panel_v2')
			? ActiveCommentsQuery
			: CommentsPanelQuery;

		// NOTE: The RESOLVED experience is started in CommentActions.tsx
		resolveCommentMutationFn({
			variables: mutationVariables,
			update: (cache, { data }) => {
				// readQuery can still return null which complicates TS lint errors with optional chaining
				// once we move to v3 of the apollo client we can move to cache.modify
				try {
					const dataProxy = cache.readQuery<ActiveCommentsQueryType | CommentsPanelQueryType>({
						query,
						variables: {
							pageId,
							contentStatus: ['DRAFT', 'CURRENT'] as GraphQLContentStatus[],
						},
					});

					const commentsToWrite = Object.assign({}, dataProxy?.comments);
					let totalCount = commentsToWrite.totalCount || 0;
					const commentsList = commentsToWrite.nodes?.filter(
						(n) => n !== null,
					) as TopLevelComment[];

					const idxToRemove = commentsList.findIndex((c) => c.id === commentId);

					// Remove the newly resolved comment from the list if we can find it
					if (idxToRemove !== -1) {
						commentsList.splice(idxToRemove, 1);
						totalCount--;
					}

					cache.writeQuery<ActiveCommentsQueryType | CommentsPanelQueryType>({
						query,
						variables: {
							pageId,
							contentStatus: ['DRAFT', 'CURRENT'] as GraphQLContentStatus[],
						},
						data: {
							comments: {
								nodes: commentsList,
								totalCount,
							},
						},
					});
				} catch (err) {
					logger.error`An error occurred when updating cache for comments panel resolve - ${err}`;
				}

				if (
					!isReply &&
					(comment as CommentData).type === CommentType.GENERAL &&
					fg('confluence_frontend_comments_panel_v2')
				) {
					try {
						const {
							confluence_resolveComments: { commentResolutionStates, success },
						} = data;
						const commentsSectionQuery = isReactionsEnabled
							? CommentsSectionQuery
							: CommentsSectionWithoutReactionsQuery;

						if (!success || commentResolutionStates.length === 0) {
							return;
						}

						const { commentId } = commentResolutionStates[0];

						const prevData = cache.readQuery<
							CommentsSectionQueryType | CommentsSectionWithoutReactionsQueryType,
							CommentsSectionQueryVariables
						>({
							query: commentsSectionQuery,
							variables: {
								contentId: contentId!,
								offset: '',
								first: DEFAULT_LIMIT,
							},
						});

						const edges = prevData?.comments?.edges;

						// Filter out all comments related to the parent and it's children as they will all be resolved
						const newEdges =
							edges?.filter(
								(edge) =>
									edge?.node?.id !== commentId &&
									!edge?.node?.ancestors.map((a) => a?.id).includes(commentId),
							) || [];

						const newData = {
							comments: {
								...prevData?.comments,
								totalCount: newEdges.length,
								pageInfo: {
									...prevData?.comments?.pageInfo,
									endCursor: '',
									hasNextPage: false, //set hasNextPage to always false to avoid performing incremental fetching again which will result in duplicate data
								},
								edges: newEdges,
							},
							content: {
								...prevData?.content,
								nodes: [...(prevData?.content?.nodes || [])],
							},
							user: {
								...prevData?.user,
							},
						};

						cache.writeQuery({
							query: commentsSectionQuery,
							variables: {
								contentId,
								offset: '',
								first: DEFAULT_LIMIT,
							},
							data: {
								...newData,
							},
						});
					} catch (err) {
						logger.error`An error occurred when updating cache for general comments resolve - ${err}`;
					}
				}
			},
		})
			.then(() => {
				updateCommentCount({
					threadKey,
					actionType: CommentActionType.RESOLVE_COMMENT_THREAD,
					commentType,
				});

				// Update the state for panel experience
				const commentsDataMapClone = { ...commentsDataMap };
				const commentInMap = commentsDataMapClone[commentType][threadKey];

				if (commentInMap) {
					commentsDataMapClone[commentType][threadKey].isOpen = false;

					addNewCommentThreads(commentsDataMapClone);
				}

				handleResolveSuccess({
					commentId,
					parentCommentMarkerRef: threadKey,
					pageId,
					pageMode,
					eventEmitter,
					source: AnalyticsSource.COMMENTS_PANEL,
					removeUnresolvedInlineComment,
					createAnalyticsEvent,
					setEditorViewProps,
					//getInlineNodeTypes  // TODO: Figure out how to get this info
				});

				setOrderedActiveAnnotationIdList(
					orderedActiveAnnotationIdList
						.map((status) => status.threadKey)
						.filter((id) => id !== threadKey),
				);

				// Show the resolved flag
				if (fg('confluence_frontend_comments_panel_v2')) {
					void flags?.showSuccessCircleFlag({
						id: commentId,
						title: formatMessage(commentsI18n.resolvedFlagText),
						isAutoDismiss: true,
						actions: [
							{
								content: formatMessage(commentsI18n.goToCommentCTA),
								onClick: () => {
									if (!isCommentsPanelShown) {
										showCommentsPanel({ openWithView: ViewValues.RESOLVED });
									} else {
										setCurrentView(ViewValues.RESOLVED);
									}

									scrollCommentsPanelToThread(threadKey);

									void flags.hideFlag(flags.state.flags[0]?.id);
								},
							},
							{
								content: formatMessage(commentsI18n.undoCTA),
								onClick: () => {
									if (!isCommentsPanelShown) {
										showCommentsPanel({ openWithView: ViewValues.OPEN });
									}

									handleReopenComment();
									void flags.hideFlag(flags.state.flags[0]?.id);
								},
							},
						],
					});
				}

				experienceTracker.succeed({
					name: RESOLVE_INLINE_COMMENT_EXPERIENCE,
				});
			})
			.catch((err) => {
				void handleMutationFailure({
					experienceTracker,
					experienceName: RESOLVE_INLINE_COMMENT_EXPERIENCE,
					error: err,
				});
			});
	};

	const pageType = pageInfo?.type ?? '';

	// Editor setup
	const spaceId = pageInfo?.space?.id ?? '';

	// User page permissions
	const operations = pageInfo?.operations || [];

	// The user can upload media only if they have update permissions for the page
	const hasMediaUploadPermissions = operations.some(
		(op) => op?.operation === 'update' && op?.targetType === pageType,
	);

	// We only want to focus resolved comments because the 'active' comments have
	// their own treatment for when one is hovered/selected and could cause weird behavior
	const isCommentFocused = resolveProperties?.resolved && commentId === focusedCommentId;

	const supportedActions =
		!isReply && isCommentRemovedByAnotherUser
			? (['reopen'] as CommentAction[])
			: supportedTopLevelActions;

	return commentId === editCommentId ? (
		<EditComment
			pageId={pageId}
			pageType={pageType}
			annotationId={threadKey}
			commentId={commentId}
			spaceId={spaceId}
			content={content ?? ''}
			isReply={isReply}
			displayCommentInViewMode={exitCommentEditor}
			avatarUrl={topCommentUserAvatar ?? ''}
			displayName={topCommentDisplayName}
			mode={pageMode === PageMode.VIEW ? 'view' : 'edit'}
			hasMediaUploadPermissions={hasMediaUploadPermissions}
			isCommentsPanel
			commentType={commentType}
		/>
	) : (
		<CommentBody
			mode="view"
			userId={userId}
			avatarUrl={topCommentUserAvatar}
			displayName={topCommentDisplayName}
			date={date ?? ''}
			dateUrl={commentUrl ?? ''}
			createdAtNonLocalized={createdAtNonLocalized ?? ''}
			versionDate={versionDate ?? ''}
			pageId={pageId}
			pageType={pageType}
			commentId={commentId ?? ''}
			isReply={isReply}
			isEdited={isEdited}
			parentCommentId={parentCommentId}
			permissions={permissions}
			content={content ?? ''}
			permissionType={permissionType ?? undefined}
			supportedActions={supportedActions}
			deleteComment={
				commentType === CommentType.INLINE ? handleDeleteInlineComment : handleDeleteGeneralComment
			}
			editComment={handleEditComment}
			resolveComment={handleResolveComment}
			numReplies={numReplies}
			isUnread={isUnread}
			isCommentActive
			isCommentsPanel
			isHovered={isHovered}
			isResolvedByAnotherUser={isResolvedByAnotherUser}
			isFocused={isCommentFocused}
			resolveProperties={resolveProperties}
			annotationId={threadKey}
			annotatedText={annotatedText}
			reopenComment={handleReopenComment}
			replyToComment={handleReplyToComment}
			inheritedReactionsData={inheritedReactionsData}
			isDeletedByAnotherUser={isDeletedByAnotherUser}
			currentlySelectedCommentMarkerRef={currentlySelectedCommentMarkerRef}
			setCurrentlySelectedCommentMarkerRef={setCurrentlySelectedCommentMarkerRef}
			isAnnotatedTextPressed={isAnnotatedTextPressed}
			setAnnotatedTextPressed={setAnnotatedTextPressed}
			commentType={commentType}
			adjustForNestedReplies={adjustForNestedReplies}
			hideBranchingStyle={hideBranchingStyle}
			canAddComments={canAddComments}
			isThreadHovered={isThreadHovered}
			isParentCommentOpen={isParentCommentOpen}
		/>
	);
};
